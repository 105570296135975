export default [
  {
    path: '/clueReportIndex',
    name: 'clueReportIndex',
    component: () => import('../views/clueReport/clueReportIndex')
  },
  {
    path: '/clueReportList',
    name: 'clueReportList',
    component: () => import('../views/clueReport/clueReportList')
  },
  {
    path: '/clueReportForm',
    name: 'clueReportForm',
    component: () => import('../views/clueReport/clueReportForm')
  },
  {
    path: '/clueReportInfo',
    name: 'clueReportInfo',
    component: () => import('../views/clueReport/clueReportInfo')
  },
  {
    path: '/mandatoryReportingIndex',
    name: 'mandatoryReportingIndex',
    component: () => import('../views/mandatoryReporting/mandatoryReportingIndex')
  },
  {
    path: '/mandatoryReportingList',
    name: 'mandatoryReportingList',
    component: () => import('../views/mandatoryReporting/mandatoryReportingList')
  },
  {
    path: '/mandatoryReportingForm',
    name: 'mandatoryReportingForm',
    component: () => import('../views/mandatoryReporting/mandatoryReportingForm')
  },
  {
    path: '/mandatoryReportingInfo',
    name: 'mandatoryReportingInfo',
    component: () => import('../views/mandatoryReporting/mandatoryReportingInfo')
  },
  {
    path: '/pcIndex',
    name: 'pcIndex',
    component: () => import('../views/pc/pcIndex')
  },
  {
    path: '/pcIndexClue',
    name: 'pcIndexClue',
    component: () => import('../views/pc/pcIndexClue')
  },
  {
    path: '/pcForm',
    name: 'pcForm',
    component: () => import('../views/pc/pcForm')
  },
  {
    path: '/pcList',
    name: 'pcList',
    component: () => import('../views/pc/pcList')
  },
  {
    path: '/pcInfo',
    name: 'pcInfo',
    component: () => import('../views/pc/pcInfo')
  },
  {
    path: '/pcFormClueReport',
    name: 'pcFormClueReport',
    component: () => import('../views/pc/pcFormClueReport')
  },
  {
    path: '/pcListClue',
    name: 'pcListClue',
    component: () => import('../views/pc/pcListClue')
  },
  {
    path: '/pcInfoClue',
    name: 'pcInfoClue',
    component: () => import('../views/pc/pcInfoClue')
  }
];
