import Router from 'vue-router';
import Vue from 'vue';
import system from './system';
import { Toast } from 'vant';
Vue.use(Router);

const router = new Router({
  base: process.env.NODE_ENV === 'production' ? '/' : '/',
  mode: 'history',
  routes: system
});

const loadingToast = Toast.loading({
  overlay: true,
  message: '加载中...'
});

router.beforeEach((to, from, next) => {
  loadingToast.open();
  // console.log(from.path + ' route to ' + to.path);
  if (to.path === from.path) {
    next(false);
  }
  if (to.meta.title) {
    //判断是否有标题
    console.log(to.meta.title);
    document.title = to.meta.title;
  } else {
    document.title = '睿童-侵害未成年人';
  }
  next();
});

// eslint-disable-next-line no-unused-vars
router.afterEach((to, from) => {
  loadingToast.clear();
});
export default router;
